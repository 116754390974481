<template>
  <div>
    <p v-if="prependText !== null" slot="prepend">
      <stemble-latex :content="prependText" />
    </p>
    <p v-if="appendText !== null" slot="append">
      <stemble-latex :content="appendText" />
    </p>

    <v-text-field
      v-format-ice="{preventNonNumericalNonX: preventNonNumericalNonX}"
      :value="value"
      v-bind="$attrs"
      class="text-center"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'IceInput',
  components: {StembleLatex},
  directives: {
    formatIce: {
      bind(el, binding) {
        let handler = function (e) {
          if (e.target.value) {
            if (binding.value.preventNonNumericalNonX) {
              e.target.value = e.target.value
                .replace(/[^xX+\-\d.]/g, '')
                .replace(/X/g, 'x')
                .replace(/^\./, '0.');
            }
            e.target.dispatchEvent(new CustomEvent('input'));
          }
        };
        el.addEventListener('input', handler);
      },
    },
  },
  inheritAttrs: false,
  props: {
    value: {},
    groupSize: {
      default: 'lg',
    },
    prependText: {
      default: null,
    },
    appendText: {
      default: null,
    },
    preventNonNumericalNonX: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatter(value) {
      if (this.preventNonNumericalNonX) {
        return value
          .replace(/[^xX+\-\d.]/g, '')
          .replace(/X/g, 'x')
          .replace(/^\./, '0.');
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped></style>
